import "./CenterInViewport.css";

import React from "react";

export const CenterInViewport: React.FC = ({ children }) => {
  return (
    <div className="center-in-viewport">
      <div>{children}</div>
    </div>
  );
};
