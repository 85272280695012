// import "antd/dist/antd.css";
import "./ElixirSale.css";

import React, { useEffect, useState } from "react";
import { useDiscordAuth } from "../helpers/discord";
import {
  useBlockchain,
  useElixirBalanceInfo,
  useSolanaBalanceInfo,
} from "../helpers/blockchain";
import { Checklist } from "../components/checklist";
import { Description } from "../components/description";
import { Elixir } from "../components/elixir";
import SVG from "react-inlinesvg";
import { useWallet } from "@solana/wallet-adapter-react";

import SilhouetteWEBP from "../../assets/elixir/avatar silhoutte.webp";
import PhantomSVG from "../../assets/icons/phantom.svg";
import DiscordSVG from "../../assets/icons/discord.svg";

export const ElixirSale: React.FC = () => {
  const blockchain = useBlockchain();
  const auth = useDiscordAuth();
  const wallet = useWallet();
  const [showSuccessElixirOverride, setShowSuccessElixirOverride] =
    React.useState(false);
  const { hasNonZeroElixir } = useElixirBalanceInfo({
    watch: showSuccessElixirOverride,
  });
  const { doesNotHaveEnoughSolToBuyElixir } = useSolanaBalanceInfo();

  const welcomeDescriptionState = React.useState(false);
  const [welcomeDescriptionIsEnabled, setWelcomeDescriptionIsEnabled] =
    welcomeDescriptionState;

  const elixirSuccessDescriptionState = React.useState(false);
  const [, setElixirSuccessDescriptionIsEnabled] =
    elixirSuccessDescriptionState;

  const errorDescriptionState = React.useState(false);
  const [, setErrorDescriptionEnabled] = errorDescriptionState;

  const [errorText, setErrorText] = React.useState<React.ReactNode>(null);

  const canPurchase =
    wallet.connected &&
    auth.isAuthenticated &&
    blockchain.canStartTransaction != undefined &&
    !hasNonZeroElixir;

  useEffect(() => {
    window.addEventListener("load", () => {
      setWelcomeDescriptionIsEnabled(true);
    });
  }, []);

  const View = () => {
    if (hasNonZeroElixir || showSuccessElixirOverride) {
      return (
        <Elixir onClick={() => setElixirSuccessDescriptionIsEnabled(true)} />
      );
    }

    return (
      <>
        <p>
          Before you can purchase an Elixir for Everseed, please link your
          Discord account and your Phantom wallet (See our how-to guide for
          instructions!)
          <br />
          <br />
          Once your purchase is complete, you must redeem your Elixir on April
          22 using the{" "}
          <a href="https://avatars.everseed.com">Everseed Avatar Creator</a>.
        </p>

        <Checklist />
      </>
    );
  };

  useEffect(() => {
    if (hasNonZeroElixir === false && doesNotHaveEnoughSolToBuyElixir) {
      setErrorText(
        <>
          You don’t seem to have enough SOL in your Phantom wallet to cover the
          purchase cost and transaction fee. Please check your wallet and try
          again when you’re ready.
        </>
      );
      setErrorDescriptionEnabled(true);
    }
  }, [hasNonZeroElixir, doesNotHaveEnoughSolToBuyElixir]);

  useEffect(() => {
    if (auth.allowedToPurchase === false) {
      setErrorText(
        <>
          You’re not authorized to purchase an <strong>Elixir</strong>. Please
          make sure the correct Discord account is linked.
        </>
      );
      setErrorDescriptionEnabled(true);
    }
  }, [auth.allowedToPurchase]);

  return (
    <div className="elixir-content">
      <div id="elixir-shop">
        <div>
          <View />
        </div>

        <button
          id="elixir-purchase-button"
          onClick={async () => {
            if (blockchain.canStartTransaction) {
              const result = await blockchain.purchaseElixir();
              if (result.error) {
                setErrorText(<>{result.error}</>);
                setErrorDescriptionEnabled(true);
              }
              if (result.success) {
                setShowSuccessElixirOverride(true);
                setElixirSuccessDescriptionIsEnabled(true);
              }
            } else {
              setErrorText(
                <>
                  You’re not authorized to purchase an <b>Elixir</b>. Please
                  make sure the correct Discord account is linked.
                </>
              );
              setErrorDescriptionEnabled(true);
            }
          }}
          disabled={!canPurchase}
          title="Purchase"
        />
      </div>

      <div id="elixir-logout-buttons">
        <button
          id="elixir-logout-discord"
          disabled={!auth.isAuthenticated}
          onClick={() => auth.logout()}
        >
          <SVG src={DiscordSVG} />
          <span>Log out of Discord</span>
        </button>

        <button
          id="elixir-unlink-wallet"
          disabled={!wallet.connected}
          onClick={() => wallet.disconnect()}
        >
          <SVG src={PhantomSVG} />
          <span>Unlink Phantom wallet</span>
        </button>
      </div>

      <button
        id="elixir-scroll-button"
        title="Elixir scroll"
        disabled={welcomeDescriptionIsEnabled}
        onClick={() => setWelcomeDescriptionIsEnabled(true)}
      />

      <Description state={elixirSuccessDescriptionState} buttonText={"Close"}>
        <h1>Success!</h1>

        <p>
          You’re the proud owner of an Everseed <b>Elixir</b>. Be sure to visit
          the <a href="https://avatars.everseed.com">Everseed Avatar Creator</a>{" "}
          on April 22 to redeem your <b>Elixir</b> and create your custom
          avatar. Elixirs held after 4/26 will automatically generate an avatar
          for you.
        </p>
      </Description>

      <Description state={errorDescriptionState} buttonText={"Close"}>
        <h1>Oh no!</h1>

        <p>{errorText}</p>
      </Description>

      <Description state={welcomeDescriptionState} buttonText={"Continue"}>
        <div>
          <div>
            <h1>Welcome!</h1>

            <p>
              If you’re hoping to purchase an <b>Elixir</b> for{" "}
              <a href="https://everseed.com/">Everseed</a>, you’ve come to the
              right place. Only a select few are authorized to obtain this
              powerful substance, so we’ll be asking you to connect your Discord
              account to check that you’re in the clear. You’ll also need to
              connect your Phantom wallet so you have a safe place to store it.
              Once you have your <b>Elixir</b>, you will need to hold onto it
              until it’s time to consume it and mint your own <b>Ranger</b>{" "}
              Avatar for Everseed. <b>Mark down these important dates</b>:
              Allowlist Elixir Sale (4/20), Waitlist Elixir Sale (4/21), Avatar
              Customization (4/22), and Avatar Birth (4/26). You won’t want to
              miss the limited time window! See the{" "}
              <a href="https://avatars.everseed.com">Everseed Avatar Creator</a>{" "}
              site for more information.
              <br />
              <br />
              To see this message again, click on the scroll icon in the top
              right of this page
            </p>
          </div>

          <img src={SilhouetteWEBP} className="description-box-silhouette" />
        </div>
      </Description>
    </div>
  );
};
