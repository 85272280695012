import React from "react";
import { CenterInViewport } from "../helpers/CenterInViewport";

export const NotAuthorized: React.FC = () => {
  return (
    <CenterInViewport>
      <h1>Not Authorized</h1>
    </CenterInViewport>
  );
};
