import "./elixir.css"

import React, { useEffect } from "react"

import ElixirWEBM from "../../assets/elixir/elixir_icon_tilting.webm"
import ElixirWEBP from "../../assets/elixir/elixir_icon_tilting.webp"

export const Elixir: React.FC<{
    onClick?: React.MouseEventHandler<HTMLButtonElement>
}> = (props) => {
    const videoRef = React.useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (videoRef.current != null) {
            videoRef.current.playbackRate = 2;
        }
    }, [videoRef, videoRef.current]);

    return (
        <div id="elixir-button">
            <button title="Elixir" onClick={props.onClick}>
                <video
                    ref={videoRef}
                    src={ElixirWEBM}
                    poster={ElixirWEBP}
                    autoPlay
                    muted
                    loop
                />
            </button>
        </div>
    );
}
