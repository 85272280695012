import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DiscordStoredInfo, storeDiscordInfo } from "../helpers/storedInfo";

export const CallbackHandler: React.FC = () => {
  const hashSubstring = window.location.hash.substring(1);
  const parsedHash = new URLSearchParams(hashSubstring);

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const tokenType = parsedHash.get("token_type");
      const accessToken = parsedHash.get("access_token");
      const expiresIn = parsedHash.get("expires_in");
      const scope = parsedHash.get("scope");

      await storeDiscordInfo({
        tokenType,
        accessToken,
        expiresIn,
        scope,
      } as DiscordStoredInfo);

      console.log("stored the data in callback handler!");
      navigate("/");
    })();
  }, [hashSubstring]);

  return null;
};
