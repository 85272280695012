import React from "react";
import { Provider } from "react-redux";
import { Route, Routes } from "react-router-dom";

import store from "./store";
import { ElixirSale } from "./views/ElixirSale";
import { NotAuthorized } from "./views/NotAuthorized";
import { CallbackHandler } from "./views/CallbackHandler";
import { ElixirSolanaProvider } from "./utils/solana";
import { Loading } from "./components/loading";
import { Footer } from "./components/footer"

export const ElixirMain: React.FC = () => {
  return (
    <ElixirSolanaProvider>
      <Provider store={store}>
        <Routes>
          <Route path="/">
            <Route index element={<ElixirSale />} />
            <Route path="not-authorized" element={<NotAuthorized />} />
            <Route path="callback" element={<CallbackHandler />} />
            <Route path="elixir">
              <Route path="callback" element={<CallbackHandler />} />
            </Route>
          </Route>
        </Routes>
        <Footer />
        <Loading />
      </Provider>
    </ElixirSolanaProvider>
  );
};
